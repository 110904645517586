import { Fade } from 'frontend/transitions/Fade';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { ClarityLoader } from '../logos/ClarityLoader';
import { ClarityLogo } from '../logos/ClarityLogo';

export interface LoginLayoutProps {
    fade?: boolean;
    height?: string;
    children?: React.ReactNode;
}

export function LoginLayout(props: LoginLayoutProps) {

    const [firstRender, setFirstRender] = useState(props.fade);
    const router = useRouter()
    const { data: userSession, mutate: mutateUser, isLoading: checkingUser } = useSWR<{ isLoggedIn: boolean, landingPage: string }>('/api/uac/user');

    useEffect(() => {
        if (!checkingUser && userSession?.isLoggedIn) {
            mutateUser().then(newVal => {
                if (newVal?.isLoggedIn) {
                    router.push('/')
                }
            })
        }
    }, [checkingUser, userSession?.isLoggedIn])


    return <section className="gradient-form flex h-full w-full justify-center bg-gray-200 align-middle md:h-screen ">
        {(checkingUser || userSession?.isLoggedIn) && <ClarityLoader size="m" className="self-center" />}
        {!checkingUser && !userSession?.isLoggedIn && (
            <Fade in={true} appear={props.fade} onEntered={() => setFirstRender(false)} className="h-full w-full">
                <ReCaptchaProvider useEnterprise>
                    <div className="relative h-full w-full bg-[radial-gradient(ellipse_at_bottom_left,_var(--tw-gradient-stops))] from-sky-200 to-blue-950">
                        <div
                            className={`absolute left-0 top-0 h-full w-full text-gray-800 transition-opacity duration-4000 ease-out ${firstRender ? 'bg-black opacity-90' : 'bg-black opacity-30'} `}
                        ></div>

                        <div className="relative z-[1000] flex h-full w-full flex-row flex-wrap items-center justify-center">
                            <div className="md:flex w-[28rem] flex-col gap-y-1 hidden">
                                <div className="flex w-64 drop-shadow-md">
                                    <ClarityLogo variant='primary-white' size={'lg'} />
                                </div>
                                {/* <div className={`${titleFont.className} uppercase tracking-wider text-xs text-gray-50`}>Quality Management</div> */}
                            </div>
                            <div style={{ height: props.height ?? '32rem' }} className={`max-h-full w-[28rem] max-w-full overflow-hidden rounded-lg bg-white p-12 drop-shadow-md`}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </ReCaptchaProvider>
            </Fade>
        )}
    </section>
}