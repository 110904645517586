import { faBalanceScale, faChartLine, faDiagramProject, faListCheck, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconCheck } from '@tabler/icons-react';
import { CurrentUser, User } from 'common/types/uac/User';
import { ClarityButton } from 'frontend/components/base/ClarityButton';
import { ClarityTextField } from 'frontend/components/base/ClarityTextField';
import { LoginLayout } from 'frontend/components/layout/LoginLayout';
import { ClarityIcon } from 'frontend/components/logos/ClarityIcon';
import { displayUserMessage } from 'frontend/components/modals/UserMessage';
import { accentFont, moduleFont } from 'frontend/fonts/font';
import { useReCaptcha } from 'next-recaptcha-v3';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import useSWR from 'swr';
import fetchJson, { FetchError } from '../../frontend/utils/fetchJson';
import { NextPageWithLayout } from '../_app';

const LoginPage: NextPageWithLayout = () => {
    const router = useRouter();

    const [loggingIn, setLoggingIn] = useState(false);
    const [username, setUsername] = useState((router.query.username as string) ?? '');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState<string>();
    const [success, setSuccess] = useState(false);

    const { executeRecaptcha, error, loaded } = useReCaptcha();

    const { data: userSession, mutate: mutateUser, isLoading: checkingUser } = useSWR<CurrentUser>('/api/uac/user');

    const login = async () => {
        if (error) console.error('Recaptcha error:', error);
        if (!loaded) {
            displayUserMessage('error', 'Recaptcha is not available. Please try again later.');
            return;
        }
        setLoggingIn(true);
        setErrorText(undefined);

        try {
            const token = await executeRecaptcha('login');
            const res = await fetchJson<{ isLoggedIn: boolean; user: User; nextUrl: string }>('/api/login', {
                method: 'POST',
                body: JSON.stringify({ username, password, recaptchaToken: token }),
            });
            if (res.isLoggedIn) {
                setSuccess(true);
                mutateUser();
                if (res.user.mustChangePassword) {
                    router.push('/users/password/reset');
                } else {
                    router.push(router.query.return ? (Array.isArray(router.query.return) ? router.query.return[0] : router.query.return) : '/');
                }
            }
        } catch (error) {
            if (error instanceof FetchError) {
                if (error.response.status === 401) {
                    setErrorText(error.data.message ?? 'Login failed.');
                } else if (error.response.status === 500) {
                    setErrorText('A server error occurred. Please try again later.');
                } else {
                    setErrorText('An unexpected error happened. Please contact an admin for assistance.');
                    console.error('An unexpected error happened:', error);
                }
            } else {
                setErrorText('An unexpected error happened. Please contact an admin for assistance.');
                console.error('An unexpected error happened:', error);
            }
        } finally {
            setLoggingIn(false);
        }
    };

    return (
        <div className={`${accentFont.className} flex h-full flex-col gap-y-7`}>
            <div className="flex flex-col">
                <div className={`${moduleFont.className} text-2xl font-bold`}>
                    {router.query.loggedOut !== undefined && router.query.loggedOut === 'true' ? 'Logged out successfully.' : 'Welcome to Clarity.'}
                </div>
                {!success && !loggingIn && !errorText && <div className={`text-sm font-medium`}>Please sign in.</div>}
                {loggingIn && <div className={`text-sm font-medium`}>Logging in...</div>}
                {success && <div className={`text-sm font-medium text-green-600`}>Login successful.</div>}
                {!loggingIn && errorText && <div className={`text-sm font-medium text-red-500`}>{errorText}</div>}
            </div>

            <div className="flex flex-1 flex-row items-center justify-between px-3 text-blue-600 opacity-20">
                <FontAwesomeIcon icon={faChartLine} fixedWidth />
                <FontAwesomeIcon icon={faBalanceScale} fixedWidth />
                <FontAwesomeIcon icon={faDiagramProject} fixedWidth />
                <FontAwesomeIcon icon={faUserGroup} fixedWidth />
                <FontAwesomeIcon icon={faListCheck} fixedWidth />
            </div>
            <div className="flex flex-col justify-center">
                <div className="mb-4 uppercase">
                    <ClarityTextField placeholder="Username" width={'grow'} onValueChange={(val) => setUsername(val)} value={username} disabled={loggingIn || success} />
                </div>
                <div className="mb-1 uppercase">
                    <ClarityTextField
                        placeholder="Password"
                        width={'grow'}
                        onValueChange={(val) => setPassword(val)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                login();
                            }
                        }}
                        type="password"
                        value={password}
                        disabled={loggingIn || success}
                    />
                </div>
                <Link className={`flex justify-end text-xs ${loggingIn ? 'text-gray-400 ' : 'text-blue-500 hover:underline '}`} href={'/login/forgot'}>
                    Forgot password
                </Link>
                <div className="mt-3 pt-1 text-center">
                    <ClarityButton disabled={loggingIn || success} onClick={login} loading={loggingIn} color={success ? 'green' : 'blue'} fullWidth className="transition-colors">
                        {success ? <IconCheck className="text-white" /> : 'Continue'}
                    </ClarityButton>
                </div>
            </div>
            <hr className="flex-1" />
            <div className="flex h-10 justify-center">
                <ClarityIcon className="aspect-auto h-full max-h-full w-fit opacity-30" />
            </div>
        </div>
    );
};

LoginPage.getLayout = (page) => <LoginLayout fade>{page}</LoginLayout>;

export default LoginPage;
